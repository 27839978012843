import React from "react";
import { Form, Nav, Tab } from "react-bootstrap";
import { InscriptionLogoButton } from "../data/staticData";

function InscriptionLogoButtons({
  clearText,
  textbox2,
  textbox,
  inscription,
  textChangeHandle,
}) {
  return (
    <div>
      <Tab.Container defaultActiveKey="first">
        <Nav
          variant="pills"
          className="d-flex justify-content-center pb-3 input-tab"
        >
          {InscriptionLogoButton.map((item, index) => {
            return (
              <Nav.Item key={index}>
                <Nav.Link
                  eventKey={item?.eventKey}
                  className="px-10 bg-transparent text-black"
                  onClick={() => {
                    clearText();
                    setTimeout(() => {
                      if (textbox2.current) {
                        textbox2.current.blur();
                      }
                    }, 100);
                  }}
                >
                  {item?.buttonName}
                </Nav.Link>
              </Nav.Item>
            );
          })}
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="first">
            <div className="right-pattern d-flex gap-5 justify-content-center pb-3">
              <div>
                <Form.Control
                  className="w-56 font-bold shadow-none border-0 input-border-bottom rounded-0 bg-transparent"
                  size="sm"
                  type="text"
                  placeholder=""
                  ref={textbox}
                  value={inscription}
                  onChange={(e) => textChangeHandle(e)}
                  autoFocus
                />
                <p className="text-end text-black">{inscription.length}/28</p>
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="second">
            <div className="right-pattern d-flex gap-5 justify-content-center pb-5 opacity-0">
              <Form.Control
                className="w-56 font-bold shadow-none border-0 input-border-bottom rounded-0 bg-transparent"
                size="sm"
                type="text"
                placeholder=""
                value={inscription}
                onChange={(e) => textChangeHandle(e)}
                disabled
              />
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}

export default InscriptionLogoButtons;
