import React from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function LoadDesign({ show, setShow, loadInspirationDesign, ...props }) {
  const handleClose = () => setShow(false);
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="ShareOrSaveYourDesign"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Title id="ShareOrSaveYourDesign" className="text-center py-4">
          <h3 className="text-xl font-semibold text-hochelaga">LOAD DESIGN</h3>
        </Modal.Title>
        <Modal.Body className="px-sm-20 pb-sm-10 pt-0">
          <p className="text-black text-lg text-center">
            You’ll be able to order this design “as is” or make it your own.
          </p>
        </Modal.Body>
        <div className="border-top">
          <Row>
            <Col className="border-end d-flex justify-content-center">
              <Button
                variant="transparent"
                className="text-black border-0 p-4"
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
            </Col>
            <Col className="text-end d-flex justify-content-center">
              <Button
                variant="transparent"
                className="text-black border-0 p-4"
                onClick={() => {
                  loadInspirationDesign();
                  handleClose();
                }}
              >
                Continue
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}

export default LoadDesign;
