import { api_endpoints, api_host } from "./constants";

export default class API {
  static createSession() {
    return api_host + api_endpoints.createSession;
  }

  static variantGetList() {
    return api_host + api_endpoints.variantGetList;
  }

  static inspirationGetList() {
    return api_host + api_endpoints.inspirationGetList;
  }

  static productList() {
    return api_host + api_endpoints.productList;
  }

  static productSteps(code) {
    return api_host + api_endpoints.products + code + api_endpoints.steps;
    // return api_host + api_endpoints.productSteps + code;
  }

  static variantGet(code) {
    return api_host + api_endpoints.variantGet + code;
  }

  static variantSave() {
    return api_host + api_endpoints.variantSave;
  }

  static variantEdit(code) {
    return api_host + api_endpoints.variantEdit + code;
  }
  static variantByShortcode(code) {
    return api_host + api_endpoints.variantByShortcode + code;
  }
  static startingDesign() {
    return api_host + api_endpoints.startingDesign;
  }

  static addToCart() {
    return api_host + api_endpoints.addToCart;
  }
  static getRedemption(code) {
    return api_host + api_endpoints.getRedemptionsheet + code;
  }

  _checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else if (response.status === 401) {
      return response;
    } else {
      throw new Error(response);
    }
  }

  async fetch(url, options) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      Authorization: localStorage.getItem("UserSession"),
    };
    const response = await fetch(url, {
      headers,
      ...options,
    });
    const response_1 = await this._checkStatus(response);
    return response_1.json();
  }
}
