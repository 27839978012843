import React, { useState } from "react";
import { Button, Image, Modal, Row, Form, Col, Spinner } from "react-bootstrap";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { FiSend } from "react-icons/fi";
import { RxExit } from "react-icons/rx";
import { GrFormClose } from "react-icons/gr";
import addToCart from "../../shopify/addToCart";

function DoneModal({
  onSave,
  setShareModelShow,
  isSaving,
  isAlreadySaved,
  setIsAlreadySaved,
  customisationData,
  shortcode,
  screenshotCompleted,
  setShow,
  bodyScrollStartStop,
  config,
  ...props
}) {
  const show = props.show;

  const handleClose = () => {
    setShow(false);
    setIsAlreadySaved(false);
    bodyScrollStartStop(false);
  };
  const RedemptionCode = JSON.parse(localStorage.getItem("RedemptionCode"));
  const [loading, setLoading] = useState(false);

  const handleAddToCart = async (code, e) => {
    e.preventDefault();
    const productShopifyId =
      props.model === "HalfWallet"
        ? config?.default?.shopifyProductHalfWallet
        : props.model === "Wallet1904"
          ? config?.default?.shopifyProductFullWallet
          : props.model === "KeyChainModel1"
            ? config?.default?.shopifyProductKeychain
            : props.model === "StickOn"
              ? config?.default?.shopifyProductStickOnWallet
              : props.model === "Half Wallet - Alteryx"
                ? config?.default?.shopifyProductHalfWalletAlteryx
                : null;
    if (productShopifyId) {
      const productId = `gid://shopify/Product/${productShopifyId}`;
      // "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MzMyMjcwNTc0MDAwMw==";
      setLoading(true);
      try {
        const checkoutUrl = await addToCart(
          productId,
          customisationData,
          code,
          config,
          RedemptionCode,
        );
        window.location.replace(checkoutUrl);
        setLoading(false);
        handleClose();
        localStorage.clear();
      } catch (error) {
        setLoading(false);
        // Handle error, show a message, etc.
        console.error("Failed to add to cart:", error);
      }
    } else {
      console.error("Product id does not exist");
    }
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="ShareOrSaveYourDesign"
        centered
        show={show}
        onHide={handleClose}
        className=""
      >
        <Modal.Header className="border-0 pe-0 bg-rose-white justify-content-start">
          <Button
            variant="transparent"
            className="shadow-none border-0 p-0"
            onClick={handleClose}
          >
            <GrFormClose className="text-2xl" />
            <span className="text-black">Close</span>
          </Button>
        </Modal.Header>
        <Modal.Title
          id="ShareOrSaveYourDesign"
          className="text-center w-full bg-rose-white"
        >
          <h3 className="text-uppercase text-hochelaga">Done</h3>
        </Modal.Title>
        <Modal.Body className="pb-sm-12 pt-sm-14 bg-rose-white">
          <Form className="text-center">
            <Row className="align-items-center">
              <Col lg={6}>
                <div>
                  <div className="img-box align-items-center">
                    {screenshotCompleted ? (
                      <Image
                        src={props.screenshot}
                        className="img-cover h-full"
                        draggable="false"
                      />
                    ) : (
                      <span className="skeleton-box w-full h-full max-w-screen-sm max-w-lg-full rounded-3 h-sm-3/4"></span>
                    )}
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                {/* list of actions can be performed */}
                <Button
                  variant="fill-green"
                  className="shadow-none d-flex align-items-center rounded-6 w-full py-5 text-start"
                  onClick={async (e) => {
                    setLoading(true);
                    const response = await onSave();
                    handleAddToCart(response, e);
                  }}
                  disabled={loading}
                >
                  <AiOutlineShoppingCart className="text-xl me-4" /> Add to Cart
                  {loading && (
                    <Spinner
                      className="w-6 h-6 ms-auto"
                      animation="border"
                      role="status"
                    />
                  )}
                </Button>
                <Button
                  variant="fill-green"
                  className="shadow-none rounded-6 w-full py-5 text-start"
                  onClick={() => {
                    setShareModelShow(true);
                    handleClose();
                    bodyScrollStartStop(true);
                  }}
                >
                  <FiSend className="text-xl me-4" /> Share Design
                </Button>
                <Button
                  variant="fill-green"
                  className="shadow-none rounded-6 w-full py-5 text-start"
                  onClick={() => {
                    if (config.default.LeaveLink === "/") {
                      handleClose();
                    } else {
                      window.location.href = config.default.LeaveLink;
                    }
                  }}
                >
                  <RxExit className="text-xl me-4" /> Leave Woolly Lab
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DoneModal;
