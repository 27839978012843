import React, { useEffect, useState } from "react";
import { Button, Image, Modal, Row, Form, Col } from "react-bootstrap";
import { MdOutlineContentCopy } from "react-icons/md";
import { GrFormClose } from "react-icons/gr";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { TOKEN_ENDPOINT } from "../../utils/config";

function ShareOrSaveYourDesign({
  onSave,
  shortcode,
  isSaving,
  productDetails,
  setShow,
  show,
  screenshot,
  screenshotCompleted,
  bodyScrollStartStop,
  config,
  ...props
}) {

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const handleClose = () => {
    bodyScrollStartStop(false);
    setShow(false);
    setIsLinkCopied(false);
  };

  useEffect(() => {
    if (shortcode !== isLinkCopied) {
      setIsLinkCopied(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortcode]);

  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="ShareOrSaveYourDesign"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="border-0 pe-0 bg-rose-white justify-content-start">
          <Button
            variant="transparent"
            className="shadow-none border-0 p-0"
            onClick={handleClose}
          >
            <GrFormClose className="text-2xl" />
            <span className="text-black">Close</span>
          </Button>
        </Modal.Header>
        <Modal.Title
          id="ShareOrSaveYourDesign"
          className="text-center w-full bg-rose-white"
        >
          <h3 className="text-uppercase text-hochelaga">Share Design</h3>
        </Modal.Title>
        <Modal.Body className="py-sm-12 py-8 px-sm-20 bg-rose-white">
          <div className="text-center mb-10">
            {/* <Image src={props.screenshot} className="w-48" /> */}
            <div className="img-box">
              {screenshotCompleted ? (
                <Image
                  src={screenshot}
                  className="img-cover m-n20"
                  draggable="false"
                />
              ) : (
                <span className="skeleton-box h-full w-full max-w-screen-sm rounded-3"></span>
              )}
            </div>
          </div>
          <div className="copy-email-link-sec">
            <Form className="text-center">
              <Row>
                <Col lg={12}>
                  <Form.Group
                    className="m-auto mb-5 col-lg-6"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="text-lg font-bold">
                      Link to your design:
                    </Form.Label>
                    {shortcode && (
                      <Form.Control
                        size="sm"
                        className="text-lg shadow-none border-0 rounded-0 text-black text-center input-border-bottom bg-transparent"
                        type="text"
                        placeholder="https://customizer.woollymade.com/VPJ7"
                        value={`${TOKEN_ENDPOINT}/${
                          shortcode ? shortcode : ""
                        }`}
                      />
                    )}
                  </Form.Group>

                  {shortcode ? (
                    <CopyToClipboard
                      text={`${TOKEN_ENDPOINT}/${shortcode ? shortcode : ""}`}
                      onCopy={() => setIsLinkCopied(shortcode)}
                    >
                      <Button
                        variant="green"
                        className={`rounded-6 px-sm-24 px-18 d-inline-flex align-items-center gap-3 ${config.default.btnClasses}`}
                      >
                        <MdOutlineContentCopy className="text-xl" />
                        {isLinkCopied ? "Link Copied" : "Copy Link"}
                      </Button>
                    </CopyToClipboard>
                  ) : (
                    <Button
                      variant="green"
                      className={`rounded-6 px-sm-24 px-18 d-inline-flex align-items-center gap-3 ${config.default.btnClasses}`}
                      onClick={() => {
                        onSave(true);
                      }}
                    >
                      {/* <Image className="me-2" src={copy} /> */}
                      {isSaving ? "Generating Link…" : "Generate Link"}
                    </Button>
                  )}
                </Col>
                {/* <Col lg={6}>
                  <Form.Group className="mb-5" controlId="formBasicEmail">
                    <Form.Label className="text-lg font-bold">
                      Email your design:
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      className="text-lg shadow-none border-0 rounded-0 text-black text-center input-border-bottom bg-transparent"
                      type="email"
                      placeholder="Enter email address..."
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setSendMailbuttontext("Send Mail");
                      }}
                    />
                  </Form.Group>
                  <Button
                    variant="green"
                    className="rounded-6 px-sm-24 px-18 d-inline-flex align-items-center gap-3"
                    disabled={sendMailbuttontext === "Mail sent" ? true : false}
                    onClick={() => {
                      sendEmail();
                    }}
                  >
                    <FiSend className="text-xl" /> {sendMailbuttontext}
                  </Button>
                </Col> */}
              </Row>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ShareOrSaveYourDesign;
