import { createSlice } from "@reduxjs/toolkit";
import { inspirationGetList } from "./index";

const inspirationGetListData = createSlice({
  name: "inspirationGetList",
  initialState: { inspirationGetList: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(inspirationGetList.pending, (state) => {})
      .addCase(inspirationGetList.fulfilled, (state, action) => {
        state.inspirationGetList = action.payload;
      })
      .addCase(inspirationGetList.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
      });
  },
});

export default inspirationGetListData.reducer;
