import React from "react";
import { Image, Modal, Row, Form, Col, Button } from "react-bootstrap";
import Slider from "react-slick";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { GrFormClose } from "react-icons/gr";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  MakerLandscape,
  PremiumModal,
  MixModal,
} from "../../assets/images/index";

function HandcraftedByWoollyMade({ props, setShow }) {
  const handcraftedSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <BiChevronLeft />,
    nextArrow: <BiChevronRight />,
  };

  const show = props.show;
  const handleClose = () => setShow(false);
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="ShareOrSaveYourDesign"
        centered
        show={show}
        onHide={handleClose}
        className="handcrafted-by-woolly-made-sec"
      >
        <Modal.Header className="border-0 pe-0 bg-rose-white">
          <Button
            variant="transparent"
            className="shadow-none border-0 p-0"
            onClick={handleClose}
          >
            <GrFormClose className="text-2xl" />
            <span className="text-black">Close</span>
          </Button>
        </Modal.Header>
        <Modal.Title
          id="ShareOrSaveYourDesign"
          className="text-center w-full bg-rose-white"
        >
          <h3 className="text-uppercase text-hochelaga">
            HANDCRAFTED BY WOOLLY MADE
          </h3>
        </Modal.Title>
        <Modal.Body className="pt-sm-12 px-sm-20 pb-sm-20 py-20 bg-rose-white">
          <Form>
            <div>
              <Slider {...handcraftedSettings}>
                <div>
                  <p className="text-black mb-6 px-sm-10 font-semi-max-bold">
                    We make all of our products in Portland, OR USA
                  </p>
                  <Row className="gy-10 px-sm-10">
                    <Col xl={6}>
                      <Image src={MakerLandscape} draggable="false" />
                    </Col>
                    <Col xl={6}>
                      <p className="mb-5 text-black">
                        We’re a small team of makers, designing and producing
                        goods here in our 1,400 sq ft NW Portland maker studio.
                      </p>
                      <p className="mb-5 text-black">
                        In a world where mass manufacturing has the upper hand,
                        our growing business is supported by a community that
                        values creativity, art and craft.
                      </p>
                    </Col>
                  </Row>
                </div>
                <div>
                  <p className="text-black mb-6 px-sm-10 font-semi-max-bold">
                    We mix modern manufacutring and traditional craft
                  </p>
                  <Row className="gy-10 px-sm-10">
                    <Col xl={6}>
                      <Image src={MixModal} draggable="false" />
                    </Col>
                    <Col xl={6}>
                      <p className="mb-5 text-black">
                        We laser cut pixel-perfect parts, edge finish, and hand
                        sew.
                      </p>
                      <p className="mb-5 text-black">
                        This mix of modern manufacturing and traditional craft
                        techniques gives our products a unique aesthetic that
                        combines minimalism, precision and handcrafted
                        characteristics.
                      </p>
                    </Col>
                  </Row>
                </div>
                <div>
                  <p className="text-black mb-6 px-sm-10 font-semi-max-bold">
                    We use premium U.S. leather that gets better as it ages
                  </p>
                  <Row className="gy-10 px-sm-10">
                    <Col xl={6}>
                      <Image src={PremiumModal} draggable="false" />
                    </Col>
                    <Col xl={6}>
                      <p className="mb-5 text-black">
                        We use full-grain, vegetable tanned leather made by
                        Wicket and Craig in Pennsylvania.
                      </p>
                      <p className="mb-5 text-black">
                        Vegetable tanning is a more eco-friendly process that
                        uses organic tanning agents to create a more premium and
                        durable leather.
                      </p>
                      <p className="mb-5 text-black">
                        The more you use this leather, the better it gets.
                      </p>
                    </Col>
                  </Row>
                </div>
              </Slider>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default HandcraftedByWoollyMade;
