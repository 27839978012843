import React from "react";
import { Carousel, Modal, Row, Col, Image } from "react-bootstrap";
import "./style.scss";
import { aboutUsCarousel } from "../../data/staticData";

const AboutModal = ({ show, setShow, bodyScrollStartStop, ..._props }) => {
  const handleClose = () => {
    setShow(false);
    bodyScrollStartStop(false);
  };
  return (
    <div className="modal rounded-5">
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="d-flex me-auto d-lg-none">
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            aria-labelledby="contained-modal-title-vcenter"
            onClick={() => handleClose()}
          ></button>
          <span className="mx-2" onClick={() => handleClose()}>
            Cancel
          </span>
        </Modal.Header>
        <Modal.Body className="pb-4 px-8">
          <Row className="carousel-row mx-auto col-lg-10">
            <h5 className="main-title font-bold text-center text-lowercase">
              3 things to know about us
            </h5>
          </Row>
          <Carousel
            interval={null}
            autoPlay={false}
            loop={false}
            controls={false}
            indicators={true}
            pause="hover"
            wrap={true}
            className="carousel-height"
            data-wrap="false"
          >
            {aboutUsCarousel.map((item, index) => {
              return (
                <Carousel.Item key={index}>
                  <Row className="carousel-row mx-auto col-lg-10">
                    <Col>
                      <h5 className="mb-3 d-none d-sm-block">{item.title}</h5>
                    </Col>
                  </Row>
                  <Row className="carousel-row mx-auto col-lg-10 content-font carousel-img align-items-center">
                    <Col sm={12} lg={7} className="about-modal">
                      {index === 1 ? (
                        <iframe
                          className="mb-5"
                          src={item.image}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                        ></iframe>
                      ) : (
                        <Image
                          draggable="false"
                          className="d-block w-full mb-5"
                          src={item.image}
                        />
                      )}
                    </Col>
                    <h6 className="h5-block my-3 d-block d-sm-none">
                      {item.title}
                    </h6>
                    <Col>
                      <Carousel.Caption>
                        <p
                          className="d-none d-sm-block"
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        />
                        <p
                          className="d-block d-sm-none"
                          style={{ fontSize: "13px" }}
                          dangerouslySetInnerHTML={{ __html: item.content1 }}
                        />
                      </Carousel.Caption>
                    </Col>
                  </Row>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AboutModal;
