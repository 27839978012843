import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../apis/api";
import { loadingEnd, loadingStart } from "../setLoading/setLoading";
export const getUserSession = createAsyncThunk(
  "createUser/post",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    const backend = new API();
    try {
      const response = await backend.fetch(API.createSession(), {
        method: "POST",
      });
      localStorage.setItem(
        "UserSession",
        JSON.stringify(response.data.session_id)
      );
      dispatch(loadingEnd());
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error creating user:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);

export const getRedemption = createAsyncThunk(
  "getRedemption/GET",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    const backend = new API();
    try {
      const response = await backend.fetch(API.getRedemption(data), {
        method: "GET",
      });
      localStorage.setItem(
        "RedemptionCode",
        JSON.stringify(response?.data?.code)
      );
      dispatch(loadingEnd());
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error creating user:", error.json());
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error.json());
    }
  }
);
