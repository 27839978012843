import React from "react";
import { ColorRing } from "react-loader-spinner";
import "./style.scss";

function PageLoader() {
  return (
    <div className="loader-container">
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={["#000"]}
      />
    </div>
  );
}
export default PageLoader;
