import {
  big_pirf,
  Branding_img1,
  no_pirf,
  small_pirf,
} from "../assets/images/index";
import {
  SliderLogo,
  SliderLogo1,
  SliderLogo2,
  theOLogo,
  ducksLogo,
  mascotLogo,
} from "../assets/images/index";
import {
  InspirationDesign1,
  InspirationDesign2,
  InspirationDesign3,
  InspirationDesign4,
} from "../assets/images/index";
import {
  Elephant,
  Inscription,
  Leather,
  Patternsvg,
  Thread,
} from "../assets/images/svg";
export const staticCustomisationData = {
  front1: {
    color: "#ed8d5d",
    pattern: 2,
  },
  front2: {
    color: "#ed8d5d",
    pattern: 1,
  },
  front3: {
    color: "#ed8d5d",
    pattern: 1,
  },
  back1: {
    color: "#ed8d5d",
    pattern: 2,
  },
  back2: {
    color: "#ed8d5d",
    pattern: 1,
  },
  back3: {
    color: "#ed8d5d",
    pattern: 1,
  },
  thread: {
    color: "#FFF0BB",
  },
  branding: 2,
  logo: {
    logoId: 2,
    height: 19 * 1.7,
    width: 121 * 1.7,
    name: "Ducks",
    src: SliderLogo1,
  },
  inscription: "",
  modificationStyle: 1,
  hardwareColor: {
    color: "#FFF0BB",
  },
  phoneColor: "#FFF0BB",
};
export const leatherColor = [
  // { colorName: "BrownSugar", colorHexcode: "#E7AC79" },
  // { colorName: "Goldenrod", colorHexcode: "#F0CC7C" },
  // { colorName: "Olive", colorHexcode: "#7A7A66" },
  // { colorName: "BrightGrey", colorHexcode: "#46454C" },
  { colorName: "Tan", colorHexcode: "#ed8d5d" },
  { colorName: "Olive", colorHexcode: "#6f6550" },
  { colorName: "Yellow", colorHexcode: "#f3bf33" },
  { colorName: "Black", colorHexcode: "#444247" },
];
export const leatherPattern = [
  {
    patternName: "Big Pirf",
    patternCode: 1,
    patternThumbnail: big_pirf,
  },
  {
    patternName: "Small Pirf",
    patternCode: 2,
    patternThumbnail: small_pirf,
  },
  {
    patternName: "No Pirf",
    patternCode: 3,
    patternThumbnail: no_pirf,
  },
];

export const leatherPatternForHalfWallet = [
  {
    patternName: "None",
    patternCode: 3,
    patternThumbnail: no_pirf,
    is_image: false
  }, {
    patternName: "Perforation",
    patternCode: 2,
    patternThumbnail: small_pirf,
    is_image: true
  }
];
// export const leatherPattern = [
//   {
//     patternName: "None",
//     PatternName: "None",
//     patternCode: 1,
//     patternThumbnail: null,
//   },
//   {
//     patternName: "Small Perf",
//     PatternName: "Small Perf",
//     patternCode: 2,
//     patternThumbnail: Pattern,
//   },
//   {
//     patternName: "Oregon",
//     PatternName: "Oregon",
//     patternCode: 3,
//     patternThumbnail: Pattern1,
//   },
// ];
export const threadColor = [
  {
    colorName: "Natural",
    colorHexcode: "#FFF0BB",
    displayColorCode: "#E5DFD1",
  },
  { colorName: "Gray", colorHexcode: "#dbdbdb", displayColorCode: "#dbdbdb" },
  { colorName: "Yellow", colorHexcode: "#ffdb79", displayColorCode: "#ffdb79" },
  { colorName: "Neon", colorHexcode: "#60FF00", displayColorCode: "#60FF00" },
  { colorName: "Olive", colorHexcode: "#777364", displayColorCode: "#777364" },
  { colorName: "Brown", colorHexcode: "#a76b57", displayColorCode: "#a76b57" },
  { colorName: "Black", colorHexcode: "#192526", displayColorCode: "#192526" },
];
export const steps = [
  { stepId: 1, stepName: "Front 1", stepCodeName: "front1" },
  { stepId: 2, stepName: "Front 2", stepCodeName: "front2" },
  { stepId: 3, stepName: "Front 3", stepCodeName: "front3" },
  { stepId: 4, stepName: "Back 1", stepCodeName: "back1" },
  { stepId: 5, stepName: "Back 2", stepCodeName: "back2" },
  { stepId: 6, stepName: "Back 3", stepCodeName: "back3" },
  { stepId: 7, stepName: "Thread", stepCodeName: "thread" },
  { stepId: 8, stepName: "Logo", stepCodeName: "logo" },
  { stepId: 9, stepName: "Inscription", stepCodeName: "inscription" },
  { stepId: 10, stepName: "Hardware Color", stepCodeName: "hardwareColor" },
  { stepId: 12, stepName: "Phone Color", stepCodeName: "phoneColor" },
];
export const logos = [
  {
    logoId: 1,
    logoName: "The O",
    logoThumbnail: SliderLogo,
    logo: theOLogo,
    height: 52 * 2,
    width: 64 * 2,
  },
  {
    logoId: 2,
    logoName: "Ducks",
    logoThumbnail: SliderLogo1,
    logo: ducksLogo,
    height: 19 * 1.7,
    width: 121 * 1.7,
  },
  {
    logoId: 3,
    logoName: "Mascot",
    logoThumbnail: SliderLogo2,
    logo: mascotLogo,
    height: 56 * 2,
    width: 85 * 2,
  },
];

export const staticProductInfo = [
  {
    model: "Wallet1904",
    li: [
      "A portrait-oriented bifold with unique cross pocket design",
      "Holds 2-16 cards and folded cash",
      "Four large pockets for ease of use",
      'Measures 3.1" x 4.3" (when closed)',
      "Premium U.S. vegetable tanned leather",
      "Handmade in Portland, OR USA",
    ],
    image: "/3dmodels/Wallet1904/Wallet1904.png",
  },
  {
    model: "KeyChainModel1",
    li: [
      "Upgrade your keys!",
      "Industrial grade steel clip for your belt or bag, and a sturdy key ring",
      'Measures 4" x 1"',
      "Premium U.S. vegetable tanned leather",
      "Handmade in Portland, OR USA",
    ],
    image: "/3dmodels/KeyChainModel1/KeyChainModel1.png",
  },
  {
    model: "StickOn",
    li: [
      "Minimal cardholder that sticks to the back of your phone or case",
      "Perfect for quick access to the 1-2 cards you use most",
      "Ultra strong 3M adhesive, but can be removed if needed",
      'Measures 3.9" x 2.4" Premium U.S. vegetable-tanned leather',
      "Handmade in Portland, OR USA",
    ],
    image: "/3dmodels/StickonWallet/StickOn.png",
  },
  {
    model: "HalfWallet",
    li: [
      "Our classic cardholder and most popular wallet",
      "5 pockets hold 6-12 cards and folded cash",
      "Perforated design",
      'Measures 4" x 3"',
      "Premium U.S. vegetable-tanned leather",
      "Handmade in Portland, OR USA",
    ],
    image: "/3dmodels/halfWalletLatest/Half_Brown_2.jpeg",
  },
  {
    model: "Half Wallet - Alteryx",
    li: [
      "Our classic cardholder and most popular wallet",
      "5 pockets hold 6-12 cards and folded cash",
      "Perforated design",
      'Measures 4" x 3"',
      "Premium U.S. vegetable-tanned leather",
      "Handmade in Portland, OR USA",
    ],
    image: "/3dmodels/halfWalletLatest/Half_Brown_2.jpeg",
  },
];

export const newCustomisationData = {
  leather: {
    FRONT_PIECE_1: { color: "#ed8d5d" },
    FRONT_PIECE_2: { color: "#ed8d5d" },
    FRONT_PIECE_3: { color: "#ed8d5d" },
    BACK_PIECE_1: { color: "#ed8d5d" },
    BACK_PIECE_2: { color: "#ed8d5d" },
    BACK_PIECE_3: { color: "#ed8d5d" },
  },
  pattern: {
    FRONT_PIECE_1: { pattern: 3 },
    FRONT_PIECE_2: { pattern: 3 },
    FRONT_PIECE_3: { pattern: 3 },
    BACK_PIECE_1: { pattern: 3 },
    BACK_PIECE_2: { pattern: 3 },
    BACK_PIECE_3: { pattern: 3 },
  },
  thread: {
    color: "#ed8d5d",
  },
  logo: {
    logoId: 2,
    height: 31 * 1.7, //19
    width: 85 * 1.7, //121
    name: "Stacked Logo",
    src: Branding_img1,
  },
  inscription: "",
  modificationStyle: 1,
};
export const mainStepLogos = [
  { stepId: 0, logo: <Leather /> },
  { stepId: 1, logo: <Patternsvg /> },
  { stepId: 2, logo: <Thread /> },
  { stepId: 3, logo: <Elephant /> },
  { stepId: 4, logo: <Inscription /> },
];
export const patternSubsteps = [
  { subStepId: 0, stepCode: "FRONT_PIECE_1", subStepIndex: 0 },
  { subStepId: 1, stepCode: "BACK_PIECE_1", subStepIndex: 3 },
];
export const patternSubstep = [{ subStepId: 0, stepCode: "FRONT_PIECE_1" }];
export const inspirationDesigns = [
  {
    front1: {
      color: "#6f6550",
      pattern: 2,
    },
    front2: {
      color: "#444247",
      pattern: 1,
    },
    front3: {
      color: "#f3bf33",
      pattern: 1,
    },
    back1: {
      color: "#6f6550",
      pattern: 2,
    },
    back2: {
      color: "#444247",
      pattern: 1,
    },
    back3: {
      color: "#f3bf33",
      pattern: 1,
    },
    thread: {
      color: "#ffdb79",
    },
    branding: 2,
    logo: {
      logoId: 1,
      height: 52 * 2,
      width: 64 * 2,
      name: "The O",
      src: SliderLogo,
    },
    inscription: "",
    modificationStyle: 2,
    thumbnail: InspirationDesign1,
  },
  {
    front1: {
      color: "#f3bf33",
      pattern: 1,
    },
    front2: {
      color: "#f3bf33",
      pattern: 1,
    },
    front3: {
      color: "#f3bf33",
      pattern: 1,
    },
    back1: {
      color: "#f3bf33",
      pattern: 1,
    },
    back2: {
      color: "#f3bf33",
      pattern: 1,
    },
    back3: {
      color: "#f3bf33",
      pattern: 1,
    },
    thread: {
      color: "#192526",
    },
    branding: 2,
    logo: {
      logoId: 3,
      height: 56 * 2,
      width: 85 * 2,
      name: "Mascot",
      src: SliderLogo2,
    },
    inscription: "",
    modificationStyle: 2,
    thumbnail: InspirationDesign2,
  },
  {
    front1: {
      color: "#ed8d5d",
      pattern: 1,
    },
    front2: {
      color: "#6f6550",
      pattern: 1,
    },
    front3: {
      color: "#6f6550",
      pattern: 1,
    },
    back1: {
      color: "#ed8d5d",
      pattern: 1,
    },
    back2: {
      color: "#6f6550",
      pattern: 1,
    },
    back3: {
      color: "#6f6550",
      pattern: 1,
    },
    thread: {
      color: "#777364",
    },
    branding: 2,
    logo: {
      logoId: 1,
      height: 52 * 2,
      width: 64 * 2,
      name: "The O",
      src: SliderLogo,
    },
    inscription: "",
    modificationStyle: 1,
    thumbnail: InspirationDesign3,
  },
  {
    front1: {
      color: "#444247",
      pattern: 1,
    },
    front2: {
      color: "#444247",
      pattern: 1,
    },
    front3: {
      color: "#444247",
      pattern: 1,
    },
    back1: {
      color: "#444247",
      pattern: 1,
    },
    back2: {
      color: "#444247",
      pattern: 1,
    },
    back3: {
      color: "#444247",
      pattern: 1,
    },
    thread: {
      color: "#60FF00",
    },
    branding: 2,
    logo: {
      logoId: 2,
      height: 19 * 1.7,
      width: 121 * 1.7,
      name: "Ducks",
      src: SliderLogo1,
    },
    inscription: "",
    modificationStyle: 2,
    thumbnail: InspirationDesign4,
  },
];
