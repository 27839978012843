import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "../src/assets/scss/all.scss";
import StrictModeWrapper from "./StrictModeWrapper.js";
import { Provider } from "react-redux";
import store from "./redux/store.js";
import App from "./App.js";
const fullUrl = new URL(window.location.href);
const subdomain = fullUrl.hostname.split(".")[0];
const config = require(`./config/config.${
  subdomain === "alteryx" ? "alteryx" : "wolly"
}.${process.env.REACT_APP_NODE_ENV}`);
document.title = config?.default?.title;
const faviconLink = document.querySelector('link[rel="icon"]');
if (faviconLink) {
  faviconLink.href = config?.default?.favicon || "/default-favicon.ico"; // Replace "/default-favicon.ico" with your default favicon path
}
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <StrictModeWrapper>
    <Provider store={store}>
      <App config={config} />
    </Provider>
  </StrictModeWrapper>
);

reportWebVitals();
