import React from "react";
import { Tab, Nav, Image } from "react-bootstrap";
import { leatherPattern, steps } from "../data";

function ColorPatternTab({
  stepIndex,
  setColor,
  setPattern,
  customisationData,
  collectionData,
  patternCollectionData,
}) {
  return (
    <div className="color-pattern-tab d-block d-lg-none">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Nav variant="pills" className="d-flex justify-content-center pb-5">
          <Nav.Item>
            <Nav.Link eventKey="first" className="border-left-radius px-10">
              leather
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="second" className="border-right-radius px-10">
              Pattern
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="first" className="overflow-auto">
            <div className="left-color d-flex gap-5 ps-2 d-lg-none justify-content-center pb-14 overflow-auto">
              {stepIndex !== 8 &&
                stepIndex !== 9 &&
                stepIndex !== 7 &&
                stepIndex !== 12 &&
                collectionData &&
                collectionData?.map((value, index) => {
                  return (
                    <button
                      key={index}
                      title={value.colorName}
                      className={
                        customisationData[steps[stepIndex - 1].stepCodeName]
                          .color === value.color
                          ? "color-round rounded-circle position-relative animate mt-2 active"
                          : "color-round rounded-circle position-relative animate mt-2"
                      } // add "active" when selected
                      style={{ backgroundColor: value.color }}
                      onClick={() => setColor(value.color)}
                    >
                      <span className="color-name text-black text-sm font-semibold text-nowrap">
                        {value.name}
                      </span>
                    </button>
                  );
                })}
              {stepIndex === 7 &&
                collectionData &&
                collectionData?.map((value, index) => {
                  return (
                    <button
                      key={index}
                      title={value.color}
                      className={
                        customisationData[steps[stepIndex - 1].stepCodeName]
                          .color === value.color
                          ? "color-round rounded-circle position-relative animate active mt-2"
                          : "color-round rounded-circle position-relative animate mt-2"
                      }
                      style={{ backgroundColor: value.color }}
                      onClick={() => setColor(value.color)}
                    >
                      <span className="color-name text-black text-sm font-semibold text-nowrap">
                        {value.color}
                      </span>
                    </button>
                  );
                })}
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="second">
            <div className="right-pattern d-flex gap-5 d-lg-none justify-content-center pb-14">
              {(stepIndex === 1 || stepIndex === 4) &&
                patternCollectionData &&
                patternCollectionData?.map((value, index) => {
                  return (
                    <button
                      key={index}
                      className={
                        customisationData[steps[stepIndex - 1].stepCodeName]
                          .pattern === parseInt(value?.id)
                          ? "pattern-round rounded-circle position-relative animate active"
                          : "pattern-round rounded-circle position-relative animate"
                      } // add "active" when selected
                      style={{ backgroundColor: "#F5F0E7" }}
                      onClick={() => setPattern(parseInt(value?.id))}
                    >
                      <Image
                        src={leatherPattern[value?.id - 1]?.patternThumbnail}
                        draggable="false"
                      />
                      <span className="pattern-name text-black text-sm font-semibold">
                        {value?.name}
                      </span>
                    </button>
                  );
                })}
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}

export default ColorPatternTab;
