const fullUrl = new URL(window.location.href);
const subdomain = fullUrl.hostname.split(".")[0];
const config = require(`../config/config.${
  subdomain === "alteryx" ? "alteryx" : "wolly"
}.${process.env.REACT_APP_NODE_ENV}`);

const frontVersion = "1";

const screenSaverActiveAfter = 360;

const api_host = config.default.apiUrl;
const api_endpoints = {
  createSession: "create-session",
  variantGetList: "products/variant/list",
  inspirationGetList: "products/inspiration",
  startingDesign: "products/startingDesign",
  productList: "products/list",
  productSteps: "products/steps/",
  products: "products/",
  steps: "/steps",
  variantGet: "products/variant/",
  variantSave: "products/variant/save",
  variantEdit: "products/variant/edit/",
  variantByShortcode: "products/variant-by-shortcode/",
  addToCart: "shopify/add-to-cart",
  getRedemptionsheet: "sheet/",
};

export { api_host, api_endpoints, screenSaverActiveAfter, frontVersion };
