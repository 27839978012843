import { createSlice } from "@reduxjs/toolkit";
import { getProductStep } from "./index";

const productSteps = createSlice({
  name: "productSteps",
  initialState: { stepsData: {}, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductStep.pending, (state) => {})
      .addCase(getProductStep.fulfilled, (state, action) => {
        state.stepsData = action.payload;
      })
      .addCase(getProductStep.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
      });
  },
});

export default productSteps.reducer;
