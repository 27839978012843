import React from "react";
import { Nav, Tab } from "react-bootstrap";
import { AlteryxLogoButton } from "../data/staticData";

function AlteryxLogoButtons({ setLogoShow, logoShow }) {
  return (
    <div>
      <Tab.Container defaultActiveKey={logoShow === true ? "first" : "second"}>
        <Nav
          variant="pills"
          className="d-flex justify-content-center pb-3 input-tab"
        >
          {AlteryxLogoButton.map((item, index) => {
            return (
              <Nav.Item key={index}>
                <Nav.Link
                  eventKey={item?.eventKey}
                  className="px-10 bg-transparent text-black"
                  onClick={() => {
                    setLogoShow(item?.onClickval);
                  }}
                >
                  {item?.buttonName}
                </Nav.Link>
              </Nav.Item>
            );
          })}
        </Nav>
      </Tab.Container>
    </div>
  );
}

export default AlteryxLogoButtons;
