import { MakerLandscape, PremiumModal } from "../assets/images/index";
export const aboutUsCarousel = [
  {
    title: "#1: Our products are handcrafted with love in Portland, OR USA.",
    image: MakerLandscape,
    content:
      "We’re a small team of makers working out of a 1,400 sq ft studio in Northwest Portland. We design and produce everything ourselves, right here.<br /><br /> In a world where mass manufacturing has the upper hand, we offer an alternative for those who appreciate the craft.",
    content1:
      "We’re a small team of makers working out of a 1,400 sq ft studio in Northwest Portland. We design and produce everything ourselves, right here. In a world where mass manufacturing has the upper hand, we offer an alternative for those who appreciate the craft.",
  },
  {
    title: "#2: We mix modern manufacturing and traditional craft techniques.",
    image: "https://www.youtube.com/embed/X-y5Ju_XX7M?si=R5rJiWhaXTb4_ekE",
    content:
      "Our products have a special aesthetic that combines minimalism, precision and handcrafted characteristics.<br /><br />This comes from our unique production process featuring tools that don’t usually go together, like laser beams, sandpaper, and hand sewing needles.",
    content1:
      "Our products have a special aesthetic that combines minimalism, precision and handcrafted characteristics. This comes from our unique production process featuring tools that don’t usually go together, like laser beams, sandpaper, and hand sewing needles.",
  },
  {
    title: "#3: We use premium U.S. vegetable tanned leather.",
    image: PremiumModal,
    content:
      "Unlike most leather on the market, vegetable tanned leather is treated with organic tanning agents to create a more premium, durable, and environmentally-friendly material.<br /><br />Ours is made by Wickett and Craig in Pennsylvania and it’s worth the extra cost. The more you use this leather, the better it gets!",
    content1:
      "Unlike most leather on the market, vegetable tanned leather is treated with organic tanning agents to create a more premium, durable, and environmentally-friendly material. Ours is made by Wickett and Craig in Pennsylvania and it’s worth the extra cost. The more you use this leather, the better it gets!",
  },
];

export const varientId = [
  {
    id: 1,
    model: "Wallet1904",
    varientId: 43321758712035,
    storeFrontVarientId:
      "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MzMyMTc1ODcxMjAzNQ==",
  },
  {
    id: 1,
    model: "KeyChainModel1",
    varientId: 43322678116579,
    storeFrontVarientId:
      "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MzMyMjY3ODExNjU3OQ==",
  },
  {
    id: 1,
    model: "StickOn",
    varientId: 43322704232675,
    storeFrontVarientId:
      "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MzMyMjcwNDIzMjY3NQ==",
  },
  {
    id: 1,
    model: "HalfWallet",
    varientId: 43322705740003,
    storeFrontVarientId:
      "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MzMyMjcwNTc0MDAwMw==",
  },
];

export const AlteryxLogoButton = [
  {
    buttonName: "Alteryx",
    eventKey: "first",
    onClickval: true,
  },
  {
    buttonName: "None",
    eventKey: "second",
    onClickval: false,
  },
];

export const InscriptionLogoButton = [
  {
    buttonName: "Text",
    eventKey: "first",
  },
  {
    buttonName: "None",
    eventKey: "second",
  },
];
