import axios from "axios";

const addToCart = async (
  productId,
  customAttributes,
  code,
  config,
  RedemptionCode
) => {
  try {
    const storeName = config?.default?.shopifyStoreName;
    const accessToken = config?.default?.shopifyAccessToken;
    const globleProductId = await getVariantId(
      storeName,
      productId,
      accessToken
    );

    const flattenObject = (obj, prefix = "") => {
      return Object.keys(obj).reduce((acc, key) => {
        const nestedKey = prefix ? `${prefix}_${key}` : key;
        if (typeof obj[key] === "object" && obj[key] !== null) {
          return acc.concat(flattenObject(obj[key], nestedKey));
        } else {
          return acc.concat({ key: nestedKey, value: String(obj[key]) });
        }
      }, []);
    };
    const customAttributesArray = flattenObject(customAttributes);
    const filteredCustomAttributesArray = customAttributesArray.filter(
      (attr) => !["logo_height", "logo_width", "logo_src"].includes(attr.key)
    );

    // eslint-disable-next-line no-unused-vars
    const _customAttributesString = filteredCustomAttributesArray
      .map(
        (attr) =>
          `{key:${JSON.stringify(attr.key)},value:${JSON.stringify(
            attr.value
          )}}`
      )
      .join(",");

    const createCheckoutResponse = await axios.post(
      `https://${storeName}/api/2021-10/graphql.json`,
      {
        query: `
          mutation {
            checkoutCreate(input: {}) {
              checkout {
                id
                webUrl
              }
            }
          }
        `,
      },
      {
        headers: {
          "X-Shopify-Storefront-Access-Token": accessToken,
          "Content-Type": "application/json",
        },
      }
    );

    const checkoutId =
      createCheckoutResponse.data.data.checkoutCreate.checkout.id;

    await axios.post(
      `https://${storeName}/api/2021-10/graphql.json`,
      {
        query: `
          mutation {
            checkoutLineItemsAdd(checkoutId: "${checkoutId}", lineItems: [
              {
                variantId: "${globleProductId}",
                quantity: 1,
                customAttributes: [
                  { key: "code", value: "${code}" },
                  ${
                    RedemptionCode
                      ? `{ key: "Redemption code", value: "${RedemptionCode}" }`
                      : ""
                  }
                ]
              }
            ]) {
              checkout {
                id
                webUrl
              }
            }
          }
        `,
      },
      {
        headers: {
          "X-Shopify-Storefront-Access-Token": accessToken,
          "Content-Type": "application/json",
        },
      }
    );

    const checkoutUrl =
      createCheckoutResponse.data.data.checkoutCreate.checkout.webUrl;
    return checkoutUrl;
  } catch (error) {
    console.error("Error adding to cart:", error);
    throw error;
  }
};

async function getVariantId(storeName, productId, accessToken) {
  const response = await axios.post(
    `https://${storeName}/api/2021-10/graphql.json`,
    {
      query: `
        query {
          product(id: "${productId}") {
            variants(first: 1) {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      `,
    },
    {
      headers: {
        "X-Shopify-Storefront-Access-Token": accessToken,
        "Content-Type": "application/json",
      },
    }
  );

  const variantId = response.data.data.product.variants.edges[0].node.id;
  return variantId;
}
export default addToCart;
