import React from "react";
const Wallet1904 = React.lazy(() => import("./Wallet1904"));
const KeyChainModel1 = React.lazy(() => import("./KeyChainModel1"));
const StickonWallet = React.lazy(() => import("./StickonWallet"));
const HalfWallet4_12 = React.lazy(() => import("./Halfwallet4_12"));
const HalfWallet_Alteryx = React.lazy(() => import("./Half_wallet_14_2"));
const Product = ({ ...props }) => {
  const {
    model,
    customisationData,
    setStepIndex,
    setMainStepIndex,
    setactiveSubStepIndex,
    selectedColor,
    changeColor,
    selectedPattern,
    changePattern,
    setactiveSubStepPatternIndex,
    activeSubStepPatternIndex,
    mainStepIndex,
    activeSubStepIndex,
    setModelLoaded,
    config,
    logoShow
  } = props;

  if (model === "Wallet1904") {
    return (
      <Wallet1904
        {...{
          customisationData,
          setStepIndex,
          setMainStepIndex,
          setactiveSubStepIndex,
          selectedColor,
          changeColor,
          selectedPattern,
          changePattern,
          setactiveSubStepPatternIndex,
          activeSubStepPatternIndex,
          mainStepIndex,
          activeSubStepIndex,
        }}
      />
    );
  } else if (model === "KeyChainModel1") {
    return (
      <KeyChainModel1
        {...{
          customisationData,
          setStepIndex,
          setMainStepIndex,
          setactiveSubStepIndex,
          selectedColor,
          changeColor,
          selectedPattern,
          changePattern,
          setactiveSubStepPatternIndex,
          activeSubStepPatternIndex,
          activeSubStepIndex,
          mainStepIndex,
        }}
        scale={1.0}
      />
    ); //scale={2.0}
  } else if (model === "StickOn") {
    return (
      <StickonWallet
        scale={1}
        position={[0, 0.005, 0]}
        {...{
          customisationData,
          setStepIndex,
          setMainStepIndex,
          setactiveSubStepIndex,
          selectedColor,
          changeColor,
          selectedPattern,
          changePattern,
          setactiveSubStepPatternIndex,
          activeSubStepPatternIndex,
          mainStepIndex,
          activeSubStepIndex,
        }}
      />
    );
  } else if (model === "HalfWallet" ) {
    return (
      // eslint-disable-next-line react/jsx-pascal-case
      <HalfWallet4_12
        newPosition={props.position}
        {...{
          customisationData,
          setStepIndex,
          setMainStepIndex,
          changeColor,
          setactiveSubStepIndex,
          selectedColor,
          mainStepIndex,
          selectedPattern,
          changePattern,
          setactiveSubStepPatternIndex,
          activeSubStepPatternIndex,
          activeSubStepIndex,
          setModelLoaded,
          config,
          logoShow
        }}
      />
    );
  }
  else if (model === "Half Wallet - Alteryx") {
    return (
      // eslint-disable-next-line react/jsx-pascal-case
      <HalfWallet_Alteryx
        newPosition={props.position}
        {...{
          customisationData,
          setStepIndex,
          setMainStepIndex,
          changeColor,
          setactiveSubStepIndex,
          selectedColor,
          mainStepIndex,
          selectedPattern,
          changePattern,
          setactiveSubStepPatternIndex,
          activeSubStepPatternIndex,
          activeSubStepIndex,
          setModelLoaded,
          config,
          logoShow
        }}
      />
    );
  } else {
  }
};

export default Product;
