import React from "react";

const StrictModeWrapper = ({ children }) => {
  // Determine the environment
  const isDevelopment = process.env.REACT_APP_NODE_ENV === "development";

  // Conditionally render StrictMode based on the environment
  if (isDevelopment) {
    return <React.StrictMode>{children}</React.StrictMode>;
  } else {
    return <>{children}</>; // No StrictMode in production
  }
};

export default StrictModeWrapper;
