import React, { useEffect, useState } from "react";
import { Button, Offcanvas, Row, Col, Image } from "react-bootstrap";
import { InfoIcon } from "../assets/images";
import { staticProductInfo } from "../data";
import { GrFormClose } from "react-icons/gr";

function ProductInfo({
  show,
  setShow,
  customisationData,
  setCustomisationData,
  productDetails,
  fromHeader,
  bodyScrollStartStop,
  config,
  ...props
}) {
  const [productInfo, setProductInfo] = useState(staticProductInfo[0]);

  const handleClose = () => {
    setShow(false);
    bodyScrollStartStop(false);
  };
  const handleShow = () => {
    setShow(true);
    bodyScrollStartStop(true);
  };
  function setModificationStyle(params) {
    let newData = { ...customisationData, modificationStyle: params };
    setCustomisationData(newData);
    handleClose();
  }
  useEffect(() => {
    if (productDetails) {
      staticProductInfo?.map((val) => {
        if (val?.model === productDetails?.name) {
          setProductInfo(val);
        }
        return null
      });
    }
  }, [productDetails]);
  return (
    <>
      {!fromHeader && (
        <Button
          variant="transparent"
          onClick={handleShow}
          className="border-0 shadow-none p-0"
        >
          <span className="text-black font-semibold d-flex align-items-center text-xs-max">
            <Image
              src={InfoIcon}
              className=" info-icon me-2 d-none d-lg-block text-black"
              draggable="false"
            />
            {productDetails?.id && productDetails?.id === 2
              ? "About this keychain"
              : "About this Wallet"}
          </span>
        </Button>
      )}
      <Offcanvas
        show={show}
        onHide={handleClose}
        {...props}
        className="bg-vista-white modification-offcanvas"
      >
        <Offcanvas.Header className="justify-content-between">
          <div
            variant="transparent"
            className="shadow-none border-0 p-0 text-start"
          >
            <Image
              src={InfoIcon}
              className="info-icon me-1 mt-n1 text-black w-5 ms-n1"
              draggable="false"
            />
            <span className="text-black">
              {productDetails?.display_name === "Half Wallet - Alteryx"
                ? "Half Wallet"
                : productDetails?.display_name}
            </span>
            {config.default.name !== "alteryx" && (
              <div className="text-start text-black pt-2">
                {productDetails?.id === 4
                  ? "$80 ($95 custom)"
                  : "$" + productDetails?.price}
              </div>
            )}
          </div>
          <Button
            variant="transparent"
            className="shadow-none border-0 p-0"
            onClick={handleClose}
          >
            <GrFormClose className="text-2xl" />
          </Button>
        </Offcanvas.Header>
        <Offcanvas.Body className="scroll-hide">
          <Row className="gy-5 justify-content-center  align-items-center">
            <Col
              xl={5}
              md={6}
              col={12}
              className="d-flex justify-content-center align-items-center"
              onClick={() => {
                setModificationStyle(1);
              }}
            >
              <Image
                src={productInfo?.image}
                className="mb-5"
                role="button"
                draggable="false"
              />
            </Col>
            <Col xl={5}>
              <div className="d-flex justify-content-center align-items-center">
                <div>
                  <h1 className="text-2xl mb-8">
                    The
                    {productDetails?.display_name === "Half Wallet - Alteryx"
                      ? " Half Wallet"
                      : `${" "}${productDetails?.display_name}`}
                  </h1>
                  <h1 className="d-none d-md-none mb-3">
                    {"$" + productDetails?.price}
                  </h1>

                  <ul className="text-sm text-black mb-lg-10 lh-relaxed">
                    {productInfo.li.map((item, index) => {
                      return (
                        <li
                          dangerouslySetInnerHTML={{ __html: item }}
                          key={index}
                        />
                      );
                    })}
                  </ul>
                  {productDetails?.id && productDetails?.id === 3 && (
                    <>
                      <h5 className="text-black font-weight-bold mb-3 mt-3">
                        Will the Stick-On fit my phone?
                      </h5>
                      <p className="text-sm text-black mb-3 mt-3">
                        Most likely! The Stick-On is designed to fit a credit
                        card, not a phone. It works great with most phones and
                        phone cases as long as the surface is clean and smooth
                      </p>
                    </>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default ProductInfo;
