import { configureStore } from "@reduxjs/toolkit";
import loaderSlice from "./setLoading/setLoading";
import userSlice from "./userSessionSlice/userSessionSlice";
import inspirationGetListData from "./inspirationGetList/inspirationGetList";
import productSteps from "./productSteps/getproductSteps";

const store = configureStore({
  reducer: {
    users: userSlice,
    loader: loaderSlice,
    inspirationGetList: inspirationGetListData,
    productSteps: productSteps,
  },
});

export default store;
