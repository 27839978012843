const pattern = (props) => (
  <svg
    width="48"
    height="41"
    viewBox="0 0 48 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <ellipse
        cx="8.88283"
        cy="11.4754"
        rx="1.73225"
        ry="1.83567"
        fill="black"
      />
      <ellipse
        cx="2.65413"
        cy="2.29722"
        rx="1.73225"
        ry="1.83567"
        fill="black"
      />
      <ellipse
        cx="2.65413"
        cy="38.3927"
        rx="1.73225"
        ry="1.83567"
        fill="black"
      />
      <ellipse
        cx="45.9687"
        cy="2.29722"
        rx="1.73225"
        ry="1.83567"
        fill="black"
      />
      <ellipse
        cx="45.9687"
        cy="38.3927"
        rx="1.73225"
        ry="1.83567"
        fill="black"
      />
      <ellipse
        cx="19.2759"
        cy="11.4754"
        rx="1.73225"
        ry="1.83567"
        fill="black"
      />
      <ellipse
        cx="29.669"
        cy="11.4754"
        rx="1.73225"
        ry="1.83567"
        fill="black"
      />
      <ellipse
        cx="40.0626"
        cy="11.4754"
        rx="1.73225"
        ry="1.83567"
        fill="black"
      />
      <ellipse
        cx="8.88283"
        cy="29.8325"
        rx="1.73225"
        ry="1.83567"
        fill="black"
      />
      <ellipse
        cx="19.2758"
        cy="29.8325"
        rx="1.73225"
        ry="1.83567"
        fill="black"
      />
      <ellipse
        cx="29.6689"
        cy="29.8325"
        rx="1.73225"
        ry="1.83567"
        fill="black"
      />
      <ellipse
        cx="40.0625"
        cy="29.8325"
        rx="1.73225"
        ry="1.83567"
        fill="black"
      />
      <ellipse
        cx="14.0794"
        cy="2.29722"
        rx="1.73225"
        ry="1.83567"
        fill="black"
      />
      <ellipse
        cx="24.4727"
        cy="2.29722"
        rx="1.73225"
        ry="1.83567"
        fill="black"
      />
      <ellipse
        cx="34.866"
        cy="2.29722"
        rx="1.73225"
        ry="1.83567"
        fill="black"
      />
      <ellipse
        cx="14.0794"
        cy="39.0104"
        rx="1.73225"
        ry="1.83567"
        fill="black"
      />
      <ellipse
        cx="24.4732"
        cy="39.0104"
        rx="1.73225"
        ry="1.83567"
        fill="black"
      />
      <ellipse
        cx="34.8666"
        cy="39.0104"
        rx="1.73225"
        ry="1.83567"
        fill="black"
      />
      <ellipse
        cx="34.8656"
        cy="20.6543"
        rx="1.73225"
        ry="1.83567"
        fill="black"
      />
      <ellipse
        cx="45.2589"
        cy="20.6543"
        rx="1.73225"
        ry="1.83567"
        fill="black"
      />
      <ellipse
        cx="3.68544"
        cy="20.6541"
        rx="1.73225"
        ry="1.83567"
        fill="black"
      />
      <ellipse
        cx="14.0794"
        cy="20.6543"
        rx="1.73225"
        ry="1.83567"
        fill="black"
      />
      <ellipse
        cx="24.4725"
        cy="20.6543"
        rx="1.73225"
        ry="1.83567"
        fill="black"
      />
    </g>
  </svg>
);
export default pattern;
