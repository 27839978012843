import React, { useState } from "react";
import { Button, Offcanvas, Row, Col, Image } from "react-bootstrap";
import { LightWithoutCircle } from "../assets/images";
import { LoadDesign } from "./modal";
import { useSelector } from "react-redux";

function Inspiration({
  show,
  setShow,
  customisationData,
  setCustomisationData,
  fromHeader,
  setloadedFromInspiration,
  bodyScrollStartStop,
  ...props
}) {
  const [loadDesignModelShow, setLoadDesignModelShow] = useState(false);
  const inspirationGetList = useSelector((state) => state.inspirationGetList);
  const handleClose = () => {
    setShow(false);
    bodyScrollStartStop(false);
  };
  const handleShow = () => {
    setShow(true);
    bodyScrollStartStop(true);
  };
  function loadInspirationDesign(data) {
    setCustomisationData(data);
    setloadedFromInspiration(true);
    handleClose();
  }

  return (
    <>
      {!fromHeader && (
        <Button
          variant="transparent"
          onClick={handleShow}
          className="border-0 shadow-none p-0 d-flex align-items-center gap-2 right-pattern-text text-dark"
        >
          <Image
            style={{ height: "30px" }}
            src={LightWithoutCircle}
            className="d-none d-lg-inline-block"
            draggable="false"
          />
          <span className="font-semibold">Inspiration</span>
        </Button>
      )}

      <Offcanvas
        show={show}
        onHide={handleClose}
        {...props}
        className="bg-vista-white modification-offcanvas"
      >
        <Offcanvas.Header className="justify-content-end">
          <Button
            variant="transparent"
            className="shadow-none border-0 p-0"
            onClick={handleClose}
          >
            <span className="text-black font-bold p-2">Inspiration</span>
            <Image src={LightWithoutCircle} draggable="false" />
          </Button>
        </Offcanvas.Header>
        <Offcanvas.Body className="inspiration-content scroll-hide py-0 pt-14">
          {/* {isLoading && <PageLoader />} */}

          <Row className="gy-5">
            {inspirationGetList &&
              inspirationGetList?.inspirationGetList.map((value, index) => {
                return (
                  <Col
                    md={6}
                    lg={4}
                    xl={3}
                    className="text-center mt-0 mb-5"
                    key={index}
                  >
                    <div className="inspiration-col position-relative">
                      <div className="save-img">
                        <Image
                          src={value?.product_variant?.thumbnail}
                          className="h-64 w-full"
                          role="button"
                          draggable="false"
                        />
                      </div>
                      <div className="inspiration-button">
                        <Button
                          variant="outline-midnight-blue"
                          onClick={() => {
                            loadInspirationDesign(
                              value?.product_variant?.customizationData
                            );
                          }}
                        >
                          Load Design
                        </Button>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
      <LoadDesign
        show={loadDesignModelShow}
        setShow={setLoadDesignModelShow}
        loadInspirationDesign={loadInspirationDesign}
      />
    </>
  );
}

export default Inspiration;
