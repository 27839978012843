import { logo, about } from "../assets/images/index";
import { Elephant } from "../assets/images/svg";
const wollyConfig = {
  name: "wolly",
  apiUrl: "https://woolly-lab-api-production.stage.eugeniuses.com/api/",
  image: logo,
  modelId: 4,
  title: "Woolly Lab - Customize Your Wallet",
  description: "wolly description",
  shopifyStoreName: "woollymade.com",
  shopifyAccessToken: "cb05b95d892493bc52b8d28d402cc83c",
  shopifyProductHalfWallet: 6938935918705,
  shopifyProductKeychain: "",
  shopifyProductFullWallet: "",
  shopifyProductStickOnWallet: "",
  shopifyProductHalfWalletAlteryx: "",
  headerContent: `HANDCRAFTED <Image className='img cursor-pointer' draggable='false'  src='${about}' /> PORTLAND OR, USA`,
  headerColor: "wolly-header-color",
  btnClasses: "",
  mainStepLogo: <Elephant />,
  footerClasses: "footer-icon",
  dotClasses: "",
  doneBtnClass: "",
  userName: "",
  password: "",
  LeaveLink: "https://woollymade.com",
  sendgridApiKey:"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",
  apiKey: "",
  sheetId: "",
};

export default wollyConfig;
