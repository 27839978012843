import { createSlice } from "@reduxjs/toolkit";
import { getUserSession, getRedemption } from "./index";

const userSlice = createSlice({
  name: "userSession",
  initialState: { users: {}, error: null, redemption: {} },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserSession.pending, (state) => {})
      .addCase(getUserSession.fulfilled, (state, action) => {
        state.users = action.payload;
      })
      .addCase(getUserSession.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
      })
      .addCase(getRedemption.pending, (state) => {})
      .addCase(getRedemption.fulfilled, (state, action) => {
        state.redemption = action.payload;
      })
      .addCase(getRedemption.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
      });
  },
});

export default userSlice.reducer;
